<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <div>
            <h4 class="d-inline"><i class="fa fa-building"></i> All Companies</h4>
            <router-link class="float-right btn btn-success" :to="{ name: 'Admin Add Company' }">Add Company <i
                class="fa fa-plus"></i></router-link>
          </div>
          <hr class="my-4">

          <v-client-table :columns="columns" :data="companyData" :options="options" :theme="theme" id="dataTable">
            <span slot="avatar" slot-scope="props"> <span v-if="!props.row.avatar">No Avatar</span> <img
                class="vendorlogo" v-bind:src="props.row.avatar" /></span>
            <span slot="name" slot-scope="props">
              <router-link v-on:click.native="setCompany(props.row)"
                :to="{ name: 'Company', params: { id: props.row.companyid, company: props.row } }">{{ props.row.name }}
              </router-link>
            </span>
            <span slot="portal_address" slot-scope="props"><a
                :href="props.row.portal_address">{{ props.row.portal_address }}</a></span>
            <span slot="creation_date" slot-scope="props">{{ props.row.creation_date | moment("M/D/YYYY") }}</span>
            <div slot="actions" slot-scope="props">
              <router-link v-on:click.native="setCompany(props.row)" class="btn btn-sm btn-primary mr-3"
                :to="{ name: 'Company', params: { id: props.row.companyid, company: props.row } }">Managers <i
                  class="fa fa-chevron-right"></i></router-link>
              <b-btn v-b-modal.editCompany @click="sendInfo(props.row.companyid)" @ok="" variant="primary btn-sm">Edit
                <i class="fa fa-edit"></i></b-btn>
              <input class="ml-3 primary btn-sm" accept="image/x-png,image/jpeg" type="file" style="width: 190px"
                @change="onFileChanged($event)">
              <b-btn @click="uploadAvatar(props.row.companyid)" variant="primary btn-sm">Upload Avatar</b-btn>
            </div>
            <!-- <div slot="child_row" slot-scope="props">
              {{props.row.description}}</a>
            </div> -->
          </v-client-table>

        </b-card>
      </b-col>
    </b-row>
    <b-modal id="editCompany" ref="editModal" title="Edit Company" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="validateBeforeSubmit" class="pb-2">
        <label>Company Name</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="name" v-model="name" class="form-control"
            placeholder="Company Name" />
        </b-input-group>
        <i v-show="errors.has('name')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('name')"
          class="help is-danger">The company name is required.</span>

        <b-row>
          <b-col class="col-12 col-lg-6">
            <label>GST Number</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="gst" v-validate="'required'" name="gst" class="form-control"
                placeholder="GST Number" />
            </b-input-group>
            <i v-show="errors.has('gst')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('gst')"
              class="help is-danger">The GST number is required.</span>
          </b-col>
          <b-col class="col-12 col-lg-6">
            <label>Registration Company Number</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="rcn" v-validate="'required'" name="rcn" class="form-control"
                placeholder="Registration Company Number" />
            </b-input-group>
            <i v-show="errors.has('rcn')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('rcn')"
              class="help is-danger">The company number is required.</span>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col class="col-12 col-lg-6">
            <label>Phone</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="number" v-model="phone" v-validate="'required|digits:10'" name="phone" class="form-control" placeholder="Phone Number" />
            </b-input-group>
            <i v-show="errors.has('phone')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('phone')" class="help is-danger">{{ errors.first('phone') }}</span>
          </b-col>
          <b-col class="col-12 col-lg-6">
            <label>Fax</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-fax"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="fax" name="fax" class="form-control" placeholder="Fax Number" />
            </b-input-group>
          </b-col>
        </b-row> -->
        <hr>
        <b-row>
          <b-col class="col-12 col-lg-6">
            <label>Address Line 1</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="addressline1" v-validate="'required'" name="addressline2"
                class="form-control" placeholder="Address" />
            </b-input-group>
            <i v-show="errors.has('address')" class="fa fa-exclamation-triangle"></i><span
              v-show="errors.has('address')" class="help is-danger">The address is required.</span>
          </b-col>
          <b-col class="col-12 col-lg-6">
            <label>Address Line 2</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="addressline2" name="addressline2" class="form-control"
                placeholder="Address" />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 col-lg-6">
            <label>City</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="city" v-validate="'required'" name="city" class="form-control"
                placeholder="City" />
            </b-input-group>
            <i v-show="errors.has('city')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('city')"
              class="help is-danger">The city is required.</span>
          </b-col>
          <b-col class="col-12 col-lg-6">
            <label>Province</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-select type="select" :options="provinceOptions" v-model="province" v-validate="'required'"
                name="province" class="form-control" placeholder="Province" />
            </b-input-group>
            <i v-show="errors.has('province')" class="fa fa-exclamation-triangle"></i><span
              v-show="errors.has('province')" class="help is-danger">The province is required.</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 col-lg-6">
            <label>Postal Code</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="postalcode" v-validate="'required'" name="postalcode"
                class="form-control" placeholder="Postal Code" />
            </b-input-group>
            <i v-show="errors.has('postalcode')" class="fa fa-exclamation-triangle"></i><span
              v-show="errors.has('postalcode')" class="help is-danger">The postal code is required.</span>
          </b-col>
          <b-col class="col-12 col-lg-6">
            <label>Website</label>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
              </b-input-group-prepend>
              <b-form-input type="text" v-model="portal_address" name="portal_address" class="form-control"
                v-validate="'url'" placeholder="Website Address" />
            </b-input-group>
            <i v-show="errors.has('portal_address')" class="fa fa-exclamation-triangle"></i><span
              v-show="errors.has('portal_address')" class="help is-danger">The url is not valid.</span>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="col-12 col-lg-6">
            <label>Price Agreement</label>
            Price Per Day
            <div class="form-group"><input class="form-control" type="number" step="any" min="0" max="100"
                v-model="pricePerDay"></div>
            Price Per AFE Count
            <div class="form-group"><input class="form-control" type="number" step="any" min="0" max="100"
                v-model="pricePerAfe"></div>
            Percent Of Total Invoices Cost
            <div class="form-group"><input class="form-control" type="number" step="any" min="0" max="100"
                v-model="percentTotal"></div>
          </b-col>
        </b-row>

        <b-button variant="success" class="mt-4" type="submit" block>Edit Company</b-button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { ClientTable, Event } from 'vue-tables-2'
Vue.use(ClientTable)


export default {
  name: 'AllCompanies',
  components: {
    ClientTable,
    Event
  },
  data() {
    return {
      columns: ['avatar', 'name', 'addressline1', 'city', 'creation_date', 'actions'],
      companyData: [],
      options: {
        headings: {
          companyid: 'Company ID',
          name: 'Company Name',
          addressline1: 'Address',
          portal_address: 'Website',
          rcn: 'Registration Number',
          gst: 'GST',
          avatar: 'Logo',
        },
        sortable: ['companyid', 'name', 'address', 'portal_address', 'rcn', 'gst', 'city', 'province', 'creation_date',],
        filterable: ['companyid', 'name', 'address', 'portal_address', 'rcn', 'gst', 'city', 'province', 'creation_date'],
        sortIcon: { base: 'fa', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      selectedAvatarToUpload: null,
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      companyid: "",
      email: "",
      password: "",
      authority: "",
      passlimit: "",
      name: "",
      rcn: "",
      gst: "",
      addressline1: "",
      addressline2: "",
      postalcode: "",
      city: "",
      fax: 0,
      phone: 0,
      province: "",
      portal_address: "",
      fname: "",
      lname: "",
      error: "",
      password2: "",
      provinceOptions: [
        { value: null, text: 'Please select a province' },
        { value: "Alberta", text: 'Alberta' },
        { value: "British Columbia", text: 'British Columbia' },
        { value: "Manitoba", text: 'Manitoba' },
        { value: "New Brunswick", text: 'New Brunswick' },
        { value: "Newfoundland and Labrador", text: 'Newfoundland and Labrador' },
        { value: "Nova Scotia", text: 'Nova Scotia' },
        { value: "Ontario", text: 'Ontario' },
        { value: "Prince Edward Island", text: 'Prince Edward Island' },
        { value: "Quebec", text: 'Quebec' },
        { value: "Saskatchewan", text: 'Saskatchewan' },
        { value: "Northwest Territories", text: 'Northwest Territories' },
        { value: "Nunavut", text: 'Nunavut' },
        { value: "Yukon", text: 'Yukon' }
      ],
      pricePerDay: 0,
      pricePerAfe: 0,
      percentTotal: 0
    }
  },
  methods: {
    sendInfo(id) {
      var val = id;
      let data = this.companyData;
      var id = data.findIndex(function (item, i) {
        return item.companyid === val
      });

      this.id = this.companyData[id]['companyid'];
      this.name = this.companyData[id]['name'];
      this.addressline1 = this.companyData[id]['addressline1'];
      this.addressline2 = this.companyData[id]['addressline2'];
      this.postalcode = this.companyData[id]['postalcode'];
      this.portal_address = this.companyData[id]['portal_address'];
      this.rcn = this.companyData[id]['rcn'];
      this.gst = this.companyData[id]['gst'];
      this.city = this.companyData[id]['city'];
      this.province = this.companyData[id]['province'];
      this.pricePerDay = this.companyData[id]['price_per_day'];
      this.pricePerAfe = this.companyData[id]['price_per_afe'];
      this.percentTotal = this.companyData[id]['percent_total'];
      // this.fax = this.companyData[id]['fax'];
      // this.phone = this.companyData[id]['phone'];
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            id: this.id,
            name: this.name,
            addressline1: this.addressline1,
            addressline2: this.addressline2,
            postalcode: this.postalcode,
            portal_address: this.portal_address,
            rcn: this.rcn,
            gst: this.gst,
            city: this.city,
            province: this.province,
            percent_total: this.percentTotal,
            price_per_day: this.pricePerDay,
            price_per_afe: this.pricePerAfe
            // fax: this.fax,
            // phone: this.phone,
          })
          console.log('submitting this payload');
          console.log(data);
          this.$http.post('/admin/edit/company', data)
            .then(response => {
              if (response.status === 200) {
                this.$toasted.show('Company Update Successful.', { type: 'success', duration: '3000' })
                this.$router.push('/admin/all-companies')
                this.$refs.editModal.hide();
                this.loadData();
              }
            })
            .catch(error => {
              this.$toasted.show('Error.', { type: 'error', duration: '3000' })
              this.error = true;
            })
          return;
        }
      });
    },
    async readSelectedFile() {
      var reader = new FileReader();
      return new Promise((resolve, reject) => {
        var file = this.selectedAvatarToUpload;

        reader.onerror = () => {
          reader.abort();
          reject("Somthing went wrong reading the file");
        }

        reader.onloadend = () => {
          console.log("read the file ");
          console.log(reader.result);
          resolve(reader.result);
        }

        reader.readAsDataURL(file);
      });
    },
    async uploadAvatar(companyid) {

      let avatar;

      try {
        avatar = await this.readSelectedFile();
      } catch (err) {
        this.$toasted.show('Avatar upload failed: Must be either .JPG .PNG and smaller than 110kb', { type: 'error', duration: '3000' });
        return;
      }

      let payload = {
        "avatar": avatar,
        "companyid": companyid
      }

      console.log("sending payload");
      console.log(payload);

      this.$http.post('admin/edit/company_avatar', payload).then(response => {
        if (response.status === 200) {
          this.$toasted.show('Avatar Upload Success!', { type: 'success', duration: '3000' });
          this.companyData.find(company => {
            return company.companyid === companyid
          }).avatar = avatar;
        } else {
          this.$toasted.show('Avatar upload failed: Must be either .JPG .PNG and smaller than 110kb', { type: 'error', duration: '3000' });
        }
      }).catch(error => {
        this.$toasted.show('Avatar upload failed: Must be either .JPG .PNG and smaller than 110kb', { type: 'error', duration: '3000' });
      })
    },
    onFileChanged(event) {
      this.selectedAvatarToUpload = event.target.files[0]
      console.log("Got a new file");
      console.log(this.selectedAvatarToUpload);
    },
    loadData() {
      this.$http.get('admin/get/companies')
        .then(response => {
          if (response.status === 200) {
            console.log("getting company data");
            this.companyData = response.data.result;
            console.log(this.companyData);
            for (var i = 0; i < this.companyData.length; i++) {
              this.$http.get('get/company/avatar/' + this.companyData[i].companyid).then(avatarResponse => {
                if (avatarResponse.status === 200) {
                  this.companyData[i].avatar = avatarResponse.data.avatar;
                  //console.log('got avatar for company ' + company.companyid);
                  //console.log(avatarResponse);
                } else {
                  console.log('No avatar found status: ' + avatarResponse.status);
                }
              }).catch(error => {
                console.log("no avatar found");
                console.log(error);
              })
            }
          }
        })
        .catch(error => {
          this.$toasted.show('Error. ' + error, { type: 'error', duration: '3000' })
        })
    },
    setCompany(company) {
      this.$session.set('company', company)
    },
  },
  mounted: function () {
    this.loadData();
  },
}
</script>
